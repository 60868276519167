import React, { useState, useMemo, useEffect, useContext } from 'react';
import LoadingSpinner from '../states/LoadingSpinner';
import DocumentDownloadButtonEditorSchema from './DocumentDownloadButtonEditorSchema.json';

import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { TokenContext } from '../../contexts/TokenContext';

const styles = {
    ribbon: {
        width: '50px',
        fontSize: '14px',
        padding: '4px',
        position: 'absolute',
        right: '-25px',
        top: '-12px',
        textAlign: 'center',
        borderRadius: '25px',
        transform: 'rotate(20deg)',
        backgroundColor: '#FF0000',
        color: 'white'
    }
};

const DocumentDownloadButton = ({ params, data, pdfDelegate }) => {
    const { title, downloadURLField } = params;
    const [loading, setLoading] = useState(false);
    const dataRow = (data && data[0]) || data || {};
    const downloadURL = dataRow[downloadURLField];
    const tokenState = useContext(TokenContext);
    if (pdfDelegate) {
        pdfDelegate?.setPdfChildCompleteFlag([]);
    }
    const action = async () => {
        setLoading(true);
        const token = tokenState.token;
        let headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        try {
            const result = await fetch(downloadURL, {
                headers
            });
            if (result.ok) {
                const resultData = await result.json();
                const { url } = resultData || {};
                if (url) {
                    window.open(url, '_blank'); //to open new page
                } else {
                    alert(
                        'We are sorry.  We were not able to download the Officer Detail Report at this time.  You may try again, and if the issue persists, please contact your support representative.  Error: api returned with missing URL'
                    );
                }
                setLoading(false);
            } else {
                alert(
                    'We are sorry.  We were not able to download the Officer Detail Report at this time.  You may try again, and if the issue persists, please contact your support representative.  Error code: ' +
                        result.status
                );
                setLoading(false);
            }
        } catch (ex) {
            alert(
                'We are sorry.  We were not able to download the Officer Detail Report at this time.  You may try again, and if the issue persists, please contact your support representative. Error: ' +
                    ex
            );
            console.log('Exception: ', ex);
        }
    };
    return (
        <>
            {downloadURL && (
                <div className={`m-0.5 py-2 flex `}>
                    <Button
                        loading={loading}
                        type="secondary"
                        size="medium"
                        icon={<DownloadOutlined />}
                        onClick={action}>
                        {title}
                        <span style={styles.ribbon}>Beta</span>
                    </Button>
                </div>
            )}
        </>
    );
};

export { DocumentDownloadButton, DocumentDownloadButtonEditorSchema };
