import React, { useState, useMemo, useEffect } from 'react';
import { useStatsApiAbusively } from '../../hooks/useApi';
import LoadingSpinner from '../states/LoadingSpinner';

const CourseOfActionButtonComponent = ({ personAggregateRiskId }) => {
    const [buttonClicked, setButtonClicked] = useState(false);

    const payload = useMemo(() => {
        return {
            create_form: buttonClicked
        };
    }, [buttonClicked]);

    const [results, loading, error] = useStatsApiAbusively(
        `/first_sign/at_risk_officers/coa_report/${personAggregateRiskId}`,
        payload
    );

    const formActionAlreadyExists = results && results.bmsActionFormId;
    const noCoaBmsTemplateIdConfigured = results && !results.coaBmsTemplateId;
    const coaTemplateConfiguredWithNoActionForm = results && results.coaBmsTemplateId && !results.bmsActionFormId;

    useEffect(() => {
        function externalRedirectToBMSReportsPage() {
            window.location.href = results.statusHyperlink;
        }

        if (results && results.bmsActionFormId && buttonClicked) {
            externalRedirectToBMSReportsPage();
        }

        if (buttonClicked && results.errorMessage) {
            alert(results.errorMessage);
            setButtonClicked(false);
        }
    }, [results, loading, buttonClicked]);

    return (
        <div className="flex flex-col space-y-1">
            {(formActionAlreadyExists || noCoaBmsTemplateIdConfigured) && (
                <a
                    className="text-link flex flex-row items-center space-x-1"
                    target="_blank"
                    rel="noreferrer"
                    href={results.statusHyperlink}>
                    {results.status}
                </a>
            )}

            {coaTemplateConfiguredWithNoActionForm && (
                <span
                    className="text-link flex flex-row items-center space-x-1 cursor-pointer"
                    onClick={() => setButtonClicked(true)}>
                    {results.status}
                </span>
            )}
            {loading && <LoadingSpinner />}
        </div>
    );
};

export default CourseOfActionButtonComponent;
